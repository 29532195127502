import React from 'react'
import imgs from '../images/44126.jpg'
function Ourmission() {
  return (
    <div className='ourmission-div '>
      <center>  <div>
        <p className='black-text mt-2'>Our<span className='blue-text'> Mission and Vision</span></p>
        </div>
        </center>
        <div className='missiondiv'>
        <div>
            <img src={imgs} height={500} />
        </div>
        <div className='cards-divmis' >
        <div className="card addedstylecard m-4  " style={{ maxWidth: "18rem" }}>

  <div className="card-body">
   <center><h5 className="card-title">Our Mission</h5></center> 
    <p className="card-text">
    Our mission is to empower healthcare partners by providing the most advanced and efficient diagnostic pathology service. Through our cutting-edge technology and expertise, we aim to deliver accurate and timely results that enable physicians to make informed decisions about patient care. We are committed to ongoing education and collaboration with healthcare providers, with a focus on improving patient outcomes and advancing the field of healthcare.
    </p>
  </div>
</div>
<div className="card addedstylecard vission-card m-4 " style={{ maxWidth: "18rem" }}>

  <div className="card-body">
    <center><h5 className="card-title">Our Vission</h5></center>
    <p className="card-text">
    Our vision is to support standalone diagnostic centers in providing high-quality care and improved health outcomes. We partner with these centers to provide advanced diagnostic pathology services and technologies, and work closely with them to continuously improve our services and provide ongoing education. Through our collaboration, we aim to empower healthcare providers to make informed decisions and expand access to quality care for all patients.
    </p>
  </div>
</div>
        </div>
        </div>
       
    </div>
  )
}

export default Ourmission