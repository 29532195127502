import axios from 'axios';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import banner from "../images/logo.png";

function Forgetpassword() {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('a');
  const id = searchParams.get('b');

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
  }

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
  }

  const handleSavePassword = async (event) => {
    event.preventDefault();

    if (password1 !== password2) {
      // Passwords don't match, handle error
       toast.error('Passwords do not match');
      return;
    }
  

    try {
      const response = await axios.post('https://apiv2.labridge.in/auth/resetPassword', {
        userId: id,
        token: token,
        password: password1
      });

      if (response.data.success) {
        // Password reset successful, handle success
        toast.success("Password reset successfully. You can close this window");
      } else {
        // Password reset failed, handle error
        toast.error(response.data.error || 'Password reset failed');
       
      }
    } catch (error) {
      // Network error or other exception, handle error
      console.error('Password reset failed:', error.message);
    }
  }

  return (
    <div className='container'>
      <div className="row" style={{alignItems: 'center',  height: '80vh'}}>
        <div className='col-md-6 col-sm-12' style={{ justifyContent: 'center', display: 'flex' }} >
          <div className='bannerClass'>
            <img src={banner} alt='banner' className='imgClass' />
          </div>
        </div>
        <div className='col-md-6 col-sm-12' >
          <form className="login" onSubmit={handleSavePassword}>
            <h2>Forgot Password</h2>
            <p>Reset Password</p>
            <label>New Password</label>
            <input type="password" placeholder="Enter New Password" minLength={8} maxLength={16} value={password1} onChange={handlePassword1Change} required />
            <label>Confirm New Password</label>
            <input type="password" placeholder="Confirm New Password" value={password2} onChange={handlePassword2Change} required />
            <div>
              <button type="submit" className='submitButton' defaultValue="Log In">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer autoClose={4000} theme="colored" />
    </div>
  )
}

export default Forgetpassword;
