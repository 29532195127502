import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../PendingOrdersPage.css'; // Import your custom CSS file

const PendingOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const SampleDetailCard = ({ sample }) => (
    <div className="sample-card">
      <p>Sample Type: {sample.sampleType}</p>
      <p>Container: {sample.container}</p>
      <p>Barcode: {sample.barcode}</p>
    </div>
  );
  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await axios.get('https://apiv2.labridge.in/bookTestB2b/pendingtestOrder');
        setOrders(response.data.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    }
    fetchOrders();
  }, []);

  return (
    <div className="orders-container">
      {orders.map((order) => (
        <div key={order._id} className="order-card">
          {order.labhpl && <h2 className="lab-name">{order.labhpl}</h2>}
          {order.integrationBookingId && <p>Integration Booking ID: {order.integrationBookingId}</p>}
          {order.error_remark && <p className="error-remark">Error Remark: {order.error_remark}</p>}
          <div className="sample-details">
            <h4>Sample Details:</h4>
            {order.sampletype && order.sampletype.sampledetail.map((sample) => (
              <SampleDetailCard key={sample._id} sample={sample} />
            ))}
          </div>
          {order.orderItems.map((item) => (
            <div key={item._id} className="test-item">
              <h3 className="test-name">{item.product.testName}</h3>
              <p className="test-id">Test ID: {item.product.testId}</p>
              {item.product.sampleType && (
                <div className="sample-info">
                  <p className="sample-type">Sample Type: {item.product.sampleType}</p>
                  {item.product.container && <p>Container: {item.product.container}</p>}
                  {item.product.barcode && <p>Barcode: {item.product.barcode}</p>}
                </div>
              )}
              {item.product.report && <p>Report: {item.product.report}</p>}
             
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};


export default PendingOrdersPage;
