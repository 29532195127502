import Box from "@mui/material/Box";
import axios from "axios";
import jwtDecode from 'jwt-decode';
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { Circles } from 'react-loader-spinner';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";

function BookTest() {
  function checklogin() {
    if (!localStorage.getItem("userdata")) {
      history("/");
    }
  }

  const history = useNavigate();

  useEffect(() => {
    checklogin();
  }, []);

  const userdetails = JSON.parse(localStorage.getItem("userdata"));
  const [lab, setlab] = useState(userdetails.user.labids[0].labCode);
  const { id } = useParams();
  const [courier, setcourier] = useState("");
  const [total, settotal] = useState();
  const [courierid, setcourierid] = useState("");
  const [pname, setpname] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [labdata, setlabdata] = useState([]);
  const [labtests, setlabtests] = useState([]);
  const [barcode, setbarcode] = useState("");
  const [selectedtest, setselectedtest] = useState([]);
  const [doctorName, setdoctorName] = useState("Self");
  const [labhpl, setlabphl] = useState("");
  const [hospisample, sethospisample] = useState([]);
  const [prescriptionUrl, setprescriptionUrl] = useState("");
  const [prescriptionFile, setprescriptionFile] = useState("");
  const [clinicalhisory, setclinicalhisory] = useState("");
  const [isActive, setisActive] = useState(false);
  const [sampledata, setsampledata] = useState([]);
  const [alerts, setalerts] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [removedOptions, setRemovedOptions] = useState([]);
  const [sampleDataList, setSampleDataList] = useState([]);
  const [testTotalPrice, setTotalPrice] = useState(0);
  const [showSampleList, setShowSampleList] = useState(false);
  const [selectedTestList, setSelectedTestList] = useState([]);
  const [refbyIds, setrefbyids] = useState("0");
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [days, setDays] = useState(0);
  const [balance, setbalance] = useState();
  const [ifprofileflag, setisprofileflag] = useState(false);
  const [profiledata, setprofiledata] = useState([]);
  const [nameTitle, setNameTitle] = useState(''); // State for the selected name title
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

 
  // Define a list of possible name titles
  const possibleNameTitles = [
    'Mr.',
    'Mrs.',
    'Miss',
    'Master',
    'Dr.',
    'Prof.',
    'Baby.',
    'Daughter of',
    'Son of'
  ];
  const config = {
    headers: { Authorization: `Bearer ${userdetails.token}` },
  };
  const handleChange = (event) => {
    setlab(event.target.value);
    gettestsdata(event.target.value);
  };
  function prescription(e) {
    setisActive(true);
    const file = e.target.files[0];
    setprescriptionFile(e.target.files[0]);
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);
    axios
      .post(`https://apiv2.labridge.in/api/uploadcontent`, bodyFormData)
      .then((response) => {
        console.log(response.data);
        setprescriptionUrl(response.data.details);
        setisActive(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getlabdata() {
    const result = await axios.get("https://apiv2.labridge.in/bookTestB2b/labName");
    setlabdata(result.data);
  }
  async function gettestsdata(labs) {
    const result = await axios.post(
      `https://apiv2.labridge.in/bookTestB2b/gettests`,
      {
        labcode: userdetails.user.labids[0].labCode,
        id: userdetails.user.id,
      },
      config
    );
    setlabtests(result.data);
  }
console.log('====================================');
console.log(sampleDataList);
console.log('====================================');
  async function checkBarcodeExistence(barcode) {
    const apiUrl = 'https://apiv2.labridge.in/bookTestB2b/checkBarcode'; // Replace with your API endpoint
  
    try {
      const response = await axios.post(apiUrl, { barcode });
 
      if (response.data.success == false) {
        return false;
      } else {
        
        return true;
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error checking barcode existence:', error);
      return false;
    }
  }

  async function validate(){
    if(pname && gender && doctorName){
 
      if(years !=0 || months !=0 || days!=0 ){
        if((years>0 && years < 110) || (months>0 && months<13) || (days>0 && days<32) ){
       
        placeorder();}
        else{
          toast.error("Invalid Age");
        }
     }
    else{
      toast.error("Age is Missing or invalid age");
     
    }
  
    }
    else{
      toast.error("Name, gender Missing or Docter name missing");
     
    }
  }


  async function placeorder() {
        setisActive(true);

          let inteid = "";
          if (userdetails.user.labids[0].labCode === "lab06") {
            inteid = "zipatho" + Date.now();
          } else {
             inteid = "bioref" + Date.now();
          }
        
          const bookdata = await axios.post(
            "https://apiv2.labridge.in/bookTestB2b/booking/elab",
            { RefByID : userdetails.user.refbyId ? userdetails.user.refbyId : "0",
              EntityID: userdetails.user.labids[0].entityid,
              title: nameTitle,
              Name: pname,
              Years: years,
              months: months,
              days:days,
              gender: gender,
              Doctorname: doctorName,
              integrationBookingid: inteid,
              testdetails: selectedTestList,
              sampledetails: sampleDataList,
            },
            config
          );
      
          if (bookdata.data.success == true) {
            
            const result = await axios.post(
              "https://apiv2.labridge.in/bookTestB2b/patientbooking",
              {
                product: selectedTestList,
                // dateandtime:values,
                courier: courier,
                courierId: courierid,
                patientName: pname,
                age: `${days} Days , ${months} Months, ${years} Years`,
                gender: gender,
                mobile: "999999999",
                doctorName: doctorName,
                lab: lab,
                integrationBookingId: inteid,
                labhpl: userdetails.user.labName,
                prescriptionUrl: prescriptionUrl,
                clinicalhisory: clinicalhisory,
                user: userdetails.user._id,
                amount: testTotalPrice,
                title:nameTitle,
                sampledetails: sampleDataList
              },
              config
            );
            
            if (result.data.success === true) {
            setisActive(false);
            toast.success("Booking SuccessFull");
          
            resetForm();
            } else if (result.data.success === false) {
              toast.error(`${result.data.msg}`);
              
              setisActive(false);
            }
          
          }
          else{
            setisActive(false);
            toast.error(bookdata.data.messages[0]);
            if(bookdata.data.messages.length>0){
            alert(bookdata.data.messages[0]);}
            else{
              alert(bookdata.data.messages);
            }
            
          }
  }
console.log("profiledata",profiledata);
  const fetchPackageDetails = async (profileid) => {
    try {
      const response = await axios.post('https://apiv2.labridge.in/bookTestB2b/getPackagedetails', {
        testId: profileid 
      });
      console.log("required datas",response.data);
      if(response.data.success && response.data.data.length>0){
        console.log("required data",response.data.data);
        setprofiledata(profiledata => profiledata ? [...profiledata, response.data.data] : [response.data.data]);;}
    } catch (error) {
      console.error('Error fetching package details:', error);
    } 
  };
  const handleFocus = (setter) => () => {
    if (setter() === 0) {
      setter(null);
    }
  };
  
  const handleBlur = (setter) => (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      setter(0);
    } else {
      setter(value);
    }
  };
  
  useEffect(() => {
    // Fetch the token from localStorage
    if(userdetails){

    if (userdetails.token) {
      try {
        const decodedToken = jwtDecode(userdetails.token);
        const currentTime = Date.now() / 1000; // Convert to seconds

        if (decodedToken.exp < currentTime) {
          // Token has expired, redirect to the login page
          history("/")
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        // If there's an error decoding the token, redirect to login
        history("/")
      }
    } else {
      // Token not found, redirect to login
      history("/")
    }}else {
      // Token not found, redirect to login
      history("/")
    }
  }, []);
  async function getbalance(){
    const result = await axios.get(`https://apiv2.labridge.in/wallet/walletbalance/${userdetails.user._id}`,config);
    setbalance(result.data.data.balance);
  }
  const resetForm = () => {
    window.location.reload();
    setphonenumber("");
    setpname("");
    setage("");
    setlabtests([]);
    setlabdata([]);
    setbarcode("");
    setTotalPrice(0);
    settotal("");
    setlab("");
    setprescriptionUrl("");
    setdoctorName("Self");
    setselectedtest([]);
    setlabphl("");
    sethospisample([]);
    setsampledata([]);
  };

  const handleChange2 = (event) => {
    setgender(event.target.value);
  };
  const updateNewBarCode = (barcode, sample, index) => {
  //   if(userdetails.user.labids[0].labCode === "lab05"){
  //   sample.barcode = barcode;
      
  // }
  //   else{
      sample.Barcode = barcode;
      sample.barcode = barcode;
    
  };
  function updatestate() {
    for (var i = 0; i < sampledata.length; i++) {
      var count = 0;
      for (var j = 0; j < selectedtest.length; j++) {
        if (sampledata[i].Testid === selectedtest[j].testId) {
          count = 1;
        }
      }
      if (count !== 1) {
        sampledata.splice(i, 1);
        i--;
      }
    }
  }
function getprofiledata(){

}
  const OnSelectTest = (selectedList, selectedItem) => {
    setShowSampleList(false);
    setprofiledata([]);
    setSampleDataList([]);
    console.log("selectedItem", selectedItem);
    let price = 0;
    price = testTotalPrice + selectedItem.testPrice;
    setTotalPrice(price);
    for(const test of selectedList){
      if(test.isProfile =="1"){
      setisprofileflag(true);
      fetchPackageDetails(test.testId);
    }
    }
    setSelectedTestList(selectedList);
    console.log("selected", selectedList);
    setSelectedOptions([]);
    setSelectedOptions(selectedList);
    console.log('selectedOptionsinselectre', selectedOptions);
  };

  const OnRemoveTest = (selectedList, removedItem) => {
    setShowSampleList(false);
    setprofiledata([]);
    setSampleDataList([]);
    console.log("selectedItem", removedItem);
    let price = 0;
    setRemovedOptions([...removedOptions, removedItem]);
    price = testTotalPrice - removedItem.testPrice;
    setTotalPrice(price);
    for(const test of selectedList){
      if(test.isProfile =="1"){
      setisprofileflag(true);
      fetchPackageDetails(test.testId);
    }
    }
    console.log("removed", selectedList);
    setSelectedTestList(selectedList);
    console.log('selectedOptionsinremove', selectedOptions)
    // setSelectedOptions([...selectedOptions, selectedList]);
    // findIdenticalContainers(selectedList)
  };

  const findIdenticalContainers = (data) => {
    const identicalContainers = [];
      data.forEach((item) => {
        const sampleTypes = item.sampleType
          .split(",")
          .map((sampleType) => sampleType.trim());
        const containers = item.container
          .split(",")
          .map((container) => container.trim());
        const sampleid = item.sampleid
        .split(",")
        .map((sampleid) => sampleid.trim());  
        debugger;
  
        sampleTypes.forEach((sampleType, index) => {
          identicalContainers.push({
            sampleType: sampleType,
            container: containers[index],
            TestGroupID : sampleid[index]
          });
        });
      });
      // console.log(identicalContainers);
      const uniqueElements = [];
      const uniqueKeys = new Set();
  
      identicalContainers.forEach((item) => {
        const key = `${item.sampleType}-${item.container}`;
  
        if (!uniqueKeys.has(key)) {
          uniqueKeys.add(key);
          uniqueElements.push(item);
        }
      });
      const result = Object.values(uniqueElements);
      // Convert the object back to an array of objects
      setSampleDataList(result);
    
  };
  const addTest = () => {
    setShowSampleList(true);
    findIdenticalContainers(selectedTestList);
  };

  // console.log("sampleData", sampleDataList);
  // console.log("selectedtest", selectedtest);
  // console.log("hospisample", hospisample);
  // console.log("sampledata", sampledata);

  useEffect(() => {
    updatestate();
    settotal(testTotalPrice-balance);
  }, [selectedtest]);
  useEffect(() => {
    getlabdata();
    setlab(userdetails.user.labids[0].labCode);
    getbalance();
    gettestsdata(userdetails.user.labids[0].labCode);
  }, []);
  return (
    <div>
 
        <Navbar balance={balance}/>
       {isActive ?  <div className="d-flex flex-column justify-content-center align-items-center" style={{height:"80vh", width:"100%"}}><Circles
  height="80"
  width="80"
  color="#3C9BD6"
  ariaLabel="circles-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={isActive}
  /> <p>Booking in process...</p> </div> : <><div className="container mt-2 booklabdiv">
          {alerts ? (
            <div class="alert alert-success" role="alert">
              Booking Success
            </div>
          ) : (
            <div></div>
          )}

          <p className="pd-p">Patient Details</p>
          <div className="row g-3 mb-4">
         <div className="col-sm-3 ">
          <div className="input-group" style={{marginTop:"32px"}}>
              <label className="input-group-text" htmlFor="nameTitle">
                 Title
              </label>
              <select
                id="nameTitle"
                className="form-select"
                value={nameTitle}
                onChange={(e) => {
                  setNameTitle(e.target.value);
                }}
              >
                <option value="">Select Title</option>
                {possibleNameTitles.map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
            </div>
            <div className="col-sm-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form10Example1">
                  Patient Name*
                </label>

                <input
                  type="text"
                  id="form10Example1"
                  className="form-control"
                  value={pname}
                  onChange={(e) => {
                    setpname(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-sm-4">
  <div className="form-outline">
    <label className="form-label" htmlFor="ageInput">
      Age
    </label>
    <div className="d-flex">
      <div className="input-group me-2">
        <span className="input-group-text">Y</span>
        <input
          type="number"
          id="yearsInput"
          className="form-control"
          
          onFocus={handleFocus(setYears)}
          onBlur={handleBlur(setYears)}
          onChange={(e) => {
            setYears(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
      <div className="input-group me-2">
        <span className="input-group-text">M</span>
        <input
          type="number"
          id="monthsInput"
          className="form-control"
        
          onFocus={handleFocus(setMonths)}
          onBlur={handleBlur(setMonths)}
          onChange={(e) => {
            setMonths(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
      <div className="input-group">
        <span className="input-group-text">D</span>
        <input
          type="number"
          id="daysInput"
          className="form-control"
       
          onFocus={handleFocus(setDays)}
          onBlur={handleBlur(setDays)}
          onChange={(e) => {
            setDays(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
    </div>
  </div>
</div>

            <div className="col-sm-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form10Example3">
                  Gender
                </label>
                <select
                  className="select form-control"
                  id="form10Example3"
                  value={gender}
                  onChange={handleChange2}
                >
                  <option value="">Select Gender...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Other</option>
                </select>
              </div>
            </div>
            {/* <div className="col-sm-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form10Example1">
                Patient Phone Number
                </label>

                <input
                  type="hi"
                  id="form10Example1"
                  className="form-control"
                  onChange={(e) => {
                    setphonenumber(e.target.value);
                  }}
                  required
                />
              </div>
            </div> */}
            <div className="col-sm-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form10Example1">
                  Doctor Name
                </label>

                <input
                  type="text"
                  id="form10Example1"
                  className="form-control"
                  value={doctorName}
                  onChange={(e) => {
                    setdoctorName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form10Example3">
                  Lab
                </label>
                <select
                  className="select form-control"
                  id="form10Example3"
                  onChange={handleChange}
                >
                  {userdetails.user.labids.map((labdata, index) => (
                    <option
                      value={labdata.labCode}
                      selected={index === 0 ? "selected" : ""}
                    >
                      {labdata.labName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="col-sm-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form10Example1">
                  Lab/Hpl Name
                </label>

                <input
                  type="text"
                  id="form10Example1"
                  className="form-control"
                  onChange={(e) => {
                    setlabphl(e.target.value);
                  }}
                  required
                />
              </div>
            </div> */}

            <div className="mb-3 col-sm-4">
              <label htmlFor="formFile" className="form-label">
                Upload Prescription
              </label>
              <input
                className="form-control"
                onChange={prescription}
                type="file"
                id="formFile"
                required
              />
              {prescriptionFile ? (
                <p style={{color:"green"}} >{prescriptionFile.name}</p>
              ) : (
                <p>No file chosen</p>
              )}
            </div>
            <div className="col-sm-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form10Example1">
                  Clinical History
                </label>

                <input
                  type="text"
                  id="form10Example1"
                  className="form-control"
                  value={clinicalhisory}
                  onChange={(e) => {
                    setclinicalhisory(e.target.value);
                  }}
                  required
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-div pt-1 mt-5">
          <div className="container">
            <div className="row">
              <div className="col details-style">
                Test Details{" "}
                <span style={{ fontSize: "12px !important" }}>
                  (Total - {testTotalPrice})
                </span>
              </div>
            </div>
            <Box
              className="booktestlab-div1"
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Multiselect
                  options={labtests}
                  displayValue="testName"
                  onSelect={OnSelectTest}
                  onRemove={OnRemoveTest}
                  closeIcon="cancel"
                  selectedValues={selectedTestList}
                  placeholder="Select Tests"
                />
              </div>
            </Box>
            <button
              className="btn btn-success"
              style={{ marginTop: "20px" }}
              onClick={() => {
                addTest();
              }}
            >
              Add Test{" "}
            </button>
            {showSampleList ? (
              <>
             {profiledata && profiledata.length>0 ? <>
              <table className="table" style={{ marginTop: "20px" }}>
  <thead >
    <tr className='table-primary'>
      
      <th scope="col">Profile</th>
      <th scope="col">Tests</th>
     
    </tr>
  </thead>
  <tbody>
    { profiledata.map((test)=>{return <tr>
      <td>{test[0].profileName}</td>
    <td>{test[0].items.map((testname)=>{
      return <>{testname.testNAme}<br></br></>
    })}</td>
  
    </tr>
    })
  }
  </tbody>
</table>
             </>:<></>}
                   {lab=="lab05" || lab=="lab08" ? <table className="table" style={{ marginTop: "20px" }}>
  <thead >
    <tr className='table-primary'>
      
      <th scope="col">Test</th>
      <th scope="col">TAT</th>
      <th scope="col">Sample</th>
    </tr>
  </thead>
  <tbody>
    {selectedTestList.map((test)=>{return <tr>
      <td>{test.testName}</td>
    <td>{test.tat}</td>
    <td>{test.sampleType}</td>
    </tr>
    })
  }
  </tbody>
</table>:<></>}
                <table className="table" style={{ marginTop: "20px" }}>
                  <thead>
                    <tr className="table-primary">
                      <th scope="col">Sample Type</th>
                      <th scope="col">Barcode Id</th>
                      <th scope="col">Container</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sampleDataList.map((sample, index) => {
                      return (
                        <tr>
                          <td>{sample.sampleType}</td>
                          <td>
                            <input
                              style={{ width: "100%" }}
                              placeholder="Barcode"
                              value={sample.barcode}
                              onChange={(e) => {
                                setbarcode(e.target.value);
                              }}
                            ></input>
                            <br></br>
                            {/* <input style={{width:"100%"}} onBlur={(e)=>{barcode && barcode === e.target.value ? updatebarcode(barcode,test.id,sample,test.testId,test.report,test.container.split(',')[index]) : toast.error("Barcode mismatch or empty")}}></input> */}
                            <input
                              style={{ width: "100%" }}
                              placeholder="Confirm Barcode"
                              value={sample.barcode}
                              onBlur={(e) => {
                                barcode && barcode === e.target.value
                                  ? updateNewBarCode(barcode, sample, index)
                                  : toast.error("Barcode mismatch or empty");
                              }}
                            ></input>
                          </td>
                          <td>{sample.container}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
               {balance>=testTotalPrice ? <button
                  className="btn btn-success mt-2"
                  style={{ marginTop: "20px" }}
                  disabled={isButtonDisabled}
                  onClick={() => { 
                    setIsButtonDisabled(true);
                    setTimeout(() => {
                      setIsButtonDisabled(false);
                    }, 4000);
                    validate();

                  }}
                >
                  submit{" "}
                </button>:<button
    type="button"
    className="btn btn-success"
    data-toggle="modal"
    data-target="#exampleModalCenter"
  >
    Add Credit
  </button>}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Footer />
     
      <ToastContainer autoClose={2000} theme="colored" />
     <div
    className="modal fade"
    id="exampleModalCenter"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content d-flex justify-content-center align-items-center">
        <div className="modal-header">
          <center>
          <h5 className="modal-title" id="exampleModalLongTitle">
            Enter Amount
          </h5>
          <p>Minimum 100</p>
          </center>
          {/* <p
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">x</span>
          </p> */}
        </div>
        <div className="modal-body">
          <input type='text' onChange={(e)=>{settotal(e.target.value)}} />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
          { total>=100 ?
          <form
            action="https://apiv2.labridge.in/wallet/addmoney/payumoneydirect"
            method="POST"
          >
            <input
              className="amount"
              type="hidden"
              name="amount"
              defaultValue={total}
            />
            <input
              className="con"
              type="hidden"
              name="phone"
              defaultValue={userdetails.user.mobile}
            />
            <input
              type="hidden"
              name="service_provider"
              defaultValue="payu_paisa"
            />
            <input type="hidden" name="email" value={userdetails.user.email} />
            <input
              type="hidden"
              name="firstname"
              value={userdetails.user.labName}
            />
            <input
              type="hidden"
              name="productinfo"
              defaultValue={userdetails.user._id}
            />
            <input
              className="btn btn-success"
              type="submit"
              defaultValue="Pay"
            />
          </form> :<button className="btn btn-success" disabled>submit</button>
        }
        </div>
      </div>
    </div>
  </div></>}
    </div>
  );
}

export default BookTest;
