import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';

function Wallet() {
  const userdetails = JSON.parse(localStorage.getItem('userdata'));
  const [tests, settests] = useState([]);
  const [balance, setbalance] = useState();
  const [total, settotal] = useState();

  const config = {
    headers: { Authorization: `Bearer ${userdetails.token}` },
  };

  async function getdata() {
    const result = await axios.get(
      `https://apiv2.labridge.in/wallet/transactiodetails/${userdetails.user._id}`,
      config
    );
    settests(result.data.data);
  }

  async function getbalance() {
    const result = await axios.get(
      `https://apiv2.labridge.in/wallet/walletbalance/${userdetails.user._id}`,
      config
    );
    setbalance(result.data.data.balance);
  }

  useEffect(() => {
    getdata();
    getbalance();
  }, []);

  // Function to format a UTC date to Indian Standard Time (IST) without characters
  function formatToIST(dateString) {
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };

    return new Date(dateString).toLocaleString('en-US', options);
  }

  return (
    <>
      <Navbar balance={balance} />
      <div className="container">
        <div className="core-card">
          <div>
            <h6>Available Balance</h6>
            <h4>{balance}</h4>
          </div>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <button
              type="button"
              className="btn4 card_btn1"
              data-toggle="modal"
              data-target="#exampleModalCenter"
            >
              Add Balance
            </button>
          
          </div>
        </div>

        <div className="mt-2 transaction-header">Transaction History</div>
        <div className="transaction-card">
          <div className="tbl-content">
            <table cellPadding={0} cellSpacing={0} style={{ border: 'none' }}>
              <thead className="tbl-header" style={{ position: 'sticky', top: 0, border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th className="th1" style={{ border: 'none' }}>
                    Patient Name
                  </th>
                  <th className="th1" style={{ border: 'none' }}>
                    Details
                  </th>
                  <th className="th1" style={{ border: 'none' }}>
                    Amount
                  </th>
                  <th className="th1" style={{ border: 'none' }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {tests.map((data, index) => {
                  return (
                    <tr key={index} style={{ border: 'none' }}>
                      <td className="td1" style={{ border: 'none' }}>
                        <span className="fs-15 fw-500">{data.name ? data.name : ''}</span>
                        <br />
                        <span className="fs-13 fw-500">({formatToIST(data.date)})</span>
                      </td>
                      <td className="td1" style={{ border: 'none' }}>
                        {data.transationreason === 'Money Added' || data.transationreason === 'Money Refunded' ? (
                          <div style={{ color: 'green' }}>{data.transationreason}</div>
                        ) : (
                          <div style={{ color: 'red' }}>{data.transationreason}</div>
                        )}
                      </td>
                      <td className="td1" style={{ border: 'none' }}>
                        {data.transationreason === 'Money Added' || data.transationreason === 'Money Refunded' ? (
                          <div style={{ color: 'green' }}>{data.amount}</div>
                        ) : (
                          <div style={{ color: 'red' }}>{data.amount}</div>
                        )}
                      </td>
                      <td className="td1" style={{ border: 'none' }}>
                        {data.transationreason === 'Money Added' || data.transationreason === 'Money Refunded' ? (
                          <div style={{ color: 'green' }}>Credit</div>
                        ) : (
                          <div style={{ color: 'red' }}>Debit</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            <table cellPadding={0} cellSpacing={0} border={0}></table>
          </div>
        </div>

        {/* Modal */}
        <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content d-flex justify-content-center align-items-center">
              <div className="modal-header">
                <center>
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Enter Amount
                  </h5>
                  <p>Minimum 100</p>
                </center>
              </div>
              <div className="modal-body">
                <input type="text" onChange={(e) => { settotal(e.target.value) }} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
{
            total>=100  ?  <form action="https://apiv2.labridge.in/wallet/addmoney/payumoney" method="POST">
                  <input className="amount" type="hidden" name="amount" defaultValue={total} />
                  <input className="con" type="hidden" name="phone" defaultValue={userdetails.user.mobile} />
                  <input type="hidden" name="service_provider" defaultValue="payu_paisa" />
                  <input type="hidden" name="email" value={userdetails.user.email} />
                  <input type="hidden" name="firstname" value={userdetails.user.labName} />
                  <input type="hidden" name="productinfo" defaultValue={userdetails.user._id} />
                  <input className="btn btn-success" type="submit" defaultValue="Pay" />
                </form>:<button className="btn btn-success" disabled>submit</button>
}          </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Wallet;
