import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from "../component/Navbar";

const RateList = () => {
  const userdetails = JSON.parse(localStorage.getItem("userdata"));
    const config = {
    headers: { Authorization: `Bearer ${userdetails.token}` },
  };
  const [labTests, setLabTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [testsPerPage] = useState(10);
  const [balance, setbalance] = useState();
  useEffect(() => {
    // Fetch tests data from the API
    const fetchData = async () => {
      try {
        const response = await axios.post('https://apiv2.labridge.in/bookTestB2b/gettests', {
          labcode: userdetails.user.labids[0].labCode,
          id: userdetails.user.id,
        }, config);
        setLabTests(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  // Update filtered tests when the searchTerm changes
  useEffect(() => {
    const filtered = labTests.filter(test =>
      test.testName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTests(filtered);
    setCurrentPage(1); // Reset to the first page when the search term changes
  }, [searchTerm, labTests]);

  // Pagination logic
  const indexOfLastTest = currentPage * testsPerPage;
  const indexOfFirstTest = indexOfLastTest - testsPerPage;
  const currentTests = filteredTests.slice(indexOfFirstTest, indexOfLastTest);

  // Page numbers
  const pageNumbers = Array.from({ length: Math.ceil(filteredTests.length / testsPerPage) });

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  // Previous and Next button handlers
  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  async function getbalance(){
    const result = await axios.get(`https://apiv2.labridge.in/wallet/walletbalance/${userdetails.user._id}`,config);
    setbalance(result.data.data.balance);
  }
  return (
    <>
     <Navbar balance={balance}/>
    <div className="tests-container container">
      
      <h1>Test List</h1>
      <input
        type="text"
        placeholder="Search by testName"
        className='form-control mb-2'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

<TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Test Name</b></TableCell>
                      <TableCell align="left"><b>B2B</b></TableCell>
                      <TableCell align="left"><b>TAT</b></TableCell>
                      <TableCell align="left"><b>MRP</b></TableCell>
                      <TableCell align="left"><b>Sample</b></TableCell>
                      <TableCell align="left"><b>Package/Test</b></TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
          {currentTests.map(test => {
          return  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
            {test.testName}
            </TableCell>
             <TableCell align="left">{test.testPrice}</TableCell>
            <TableCell align="left">{test.tat}</TableCell>
            <TableCell align="left">{test.mrp}</TableCell>
            <TableCell align="left">{test.sampleType}</TableCell>
            <TableCell align="left">{test.isProfile=="1" ? 'Package' : 'Test'}</TableCell>
            

          </TableRow>
        })}

</TableBody>
</Table>
</TableContainer>
           
      {/* Pagination */}
      <div className="pagination">
  <button onClick={handlePrevClick} disabled={currentPage === 1}>
    Previous
  </button>
  <button onClick={handleNextClick} disabled={currentPage === pageNumbers.length}>
    Next
  </button>
</div>
    </div>
    </>
  );
};

export default RateList;
