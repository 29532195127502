import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../Css/card.css';
import img4 from '../images/Lucknow_B2B_1.png';
import img5 from '../images/Lucknow_B2B_2.png';
import img6 from '../images/Lucknow_B2B_3.png';
import img7 from '../images/Nalasopara_B2B_1.png';
import img8 from '../images/Nalasopara_B2B_2.png';
import img9 from '../images/Nalasopara_B2B_3.png';
import img1 from '../images/Thane_B2B_1..png';
import img2 from '../images/Thane_B2B_2.png';
import img3 from '../images/Thane_B2B_3.png';
import img10 from '../images/akolaoff1.png';
import img11 from '../images/akolaoff2.png';

function SpecialofferComponent() {
  const userdetails = JSON.parse(localStorage.getItem('userdata'));
  const labcode = userdetails.user.labids[0].labCode;
  let images = [];

  if (labcode === 'lab05') {
    images = [img1, img2, img3];
  } else if (labcode === 'lab06') {
    images = [img4, img5, img6];
  } else if (labcode === 'lab08') {
    images = [img7, img8, img9];
  } else if (labcode === 'lab09') {
    images = [img10, img11]; // Don't render the component
  }

  return (
    <div className="special-offer-section container">
      <h2 className="special-offer-title">Independence Day Offer</h2>
      <Carousel className="special-offer-images">
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={image}
              alt={`Independence Offer ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <Link to="/book">
        <button className='btn mt-2' style={{background:"#f9a94a", color:"white"}}>
          Book Now
        </button>
      </Link>
    </div>
  );
}

export default SpecialofferComponent;
