import React from 'react';

import OrderLabNew from './orderLabNew';

export default function orderHistory() {
  
    const userData = JSON.parse(localStorage.getItem("userdata"));
  
    return (
    <div>
            {/* {userData.user.labids[0].labCode === "lab05" ? <Orderlab/> :<OrderLabNew/>} */}
          
            <OrderLabNew/>
           
    </div>
  )
}
