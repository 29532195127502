import React from 'react';
function Whoweare() {
  return (
    <div className='whoweare-div p-3'>
      {/* <div className='container pt-5 pb-5 d-flex w-100' style={{height:'100%'}}>
        <div className='whoweareimg ms-2'>

        </div>
        <div className='whowearecontent ps-4'>
        <p className='our-div'>Who <span className='service-div'>we are →</span></p>
          <p className='whoweare-content'>Labridge fastest growing B2B aggregator and Market Place in the Healthcare Industry. It helps the 'Standalone Diagnostic Centers' get competitive Blood and RT PCR Sample Processing Rates from Hi-tech processing laboratories along with top-notch and day-to-day logistics, IT, and Marketing Support. On our Market Place, We avail our customers best Quality consumables at the lowest price As an aggregator; we benefit from Zero Competition, High Volumes, and Quick Expansion across geographies.</p>
          <button className='whoweare-button'>See more..</button>
        </div>
        </div> */}
        <div className='container whoweare-div1'>
        <div>                                                                                               
        <p className='black-text mt-2'>Who<span className='blue-text'> we are →</span></p>
        <p className='whoweare-content'>Labridge is a pioneering B2B service solution transforming the diagnostics landscape. We offer a unified technology platform that enables diagnostic players to interact, transact, and manage their commercial relationships with unprecedented ease and efficiency. Our platform is designed to streamline operations, enhance collaboration, and drive innovation, setting a new benchmark in the diagnostic industry.</p>
        <button className='whoweare-button'>See more..</button>
        </div>
      </div>

    </div>
  )
}

export default Whoweare;