
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Becomepartnerform from './pages/Addpathology';
import BillingForm from './pages/BillingForm';
import BillingPrint from './pages/BillingPrint';
import BookTest from './pages/BookTest';
import Cancellation from './pages/Cancellation';
import ContactUs from './pages/ContactUs';
import Disclaimer from './pages/Disclaimer';
import Forgetpassword from './pages/Forgetpassword.js';
import Homelab from './pages/Homelab';
import Invoice from './pages/Invoice';
import Login1 from './pages/Login1';
import NewHomelab from './pages/NewHomelab';
import OrderHistory from './pages/orderHistory.js';
import PackageDetails from './pages/PackageDetails';
import PendingOrdersPage from './pages/pendingtest';
import Privecy from './pages/Privecy';
import RateList from './pages/RateList.js';
import Refundpolicy from './pages/Refundpolicy';
import Terms from './pages/Terms';
import Wallet from './pages/Wallet';
import Whowearemain from './pages/Whowearemain';
ReactGA.initialize('G-62CQ8DRTVR');


function App() {
// Disable right-click
document.addEventListener('contextmenu', (e) => e.preventDefault());

const userData = JSON.parse(localStorage.getItem("userdata"));

// console.log(userData.user);

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U

  if (
  // e.key == 'F12' ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.ctrlKey && e.key === 'U')
  )
    return false;
};
useEffect(() => {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
}, []);
  return (
    <div className="App">
      
       <Routes>
       {/* <Route path="/" element={<Home/>} /> */}
       <Route path="/add-partner" element={<Becomepartnerform/>} />
       {/* <Route path="/register" element={<Register/>} /> */}
       <Route path="/" element={<Login1/>} />
       {/* <Route path="/become-processing-partner" element={<Becomeprocess/>} /> */}
       {/* <Route path="/book-test-online" element={<BookOnline/>} /> */}
       {/* <Route path="/test/:id" element={<ViewTest/>} /> */}
        {/* <Route path='/life-style-product' element={<Lifestyleproduct/>}/>      
        <Route path='/add-patient' element={<PatientDetails/>}/> */}
        <Route path='/home' element={<Homelab/>}/>
       
        <Route path='/order-history' element={<OrderHistory/>}/>
        
        <Route path ='/who-we-are' element={<Whowearemain/>}/>
        {/* <Route path = '/lab/book-test-everywhere' element={<Phleboservices/>}/>
        <Route path = '/find-nearest-lab' element={<Findnearest/>}/> */}
        <Route path="/wallet" element={<Wallet/>}/>
        <Route path="/ratelist" element={<RateList/>} />
        <Route path="/billing-form" element={<BillingForm/>} />
        <Route path='/invoice' element={<Invoice/>} />
        <Route path='/book' element={<BookTest/>} />
        <Route path='/packagedetails' element={<PackageDetails/>} />
        <Route path='/passwordReset' element={<Forgetpassword/>} />
       <Route path='/pendingtest' element={<PendingOrdersPage/>}/>
       <Route path='/newhomelab' element={<NewHomelab/>}/>
       <Route path='/billing' element={<BillingPrint/>} />
       <Route path="/terms-and-condition" element={<Terms/>} />
        <Route path="/policy" element={<Privecy/>} />
        <Route path="/ContactUs" element={<ContactUs/>} />
        <Route path="/refund-policy" element={<Refundpolicy/>}/>
        <Route path="/disclaimer" element={<Disclaimer/>}/>
        <Route path="/cancellation-policy" element={<Cancellation/>}/>
       </Routes>
      
    </div>
  );
}

export default App;
