import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logoImg from '../images/logo.png';


function CustomNavbar(props) {
  const [log, setlog] = useState(true);
  const [logb2c, setlogb2b] = useState(false);

  function checklogin() {
    if (!localStorage.getItem('userdata')) {
      setlog(false);
      if (localStorage.getItem('userdatab2c')) {
        setlogb2b(true);
      }
    }
  }

  useEffect(() => {
    checklogin();
  }, []);

  return (
    <Navbar bg="light" expand="lg" className="custom-navbar">
      <div className="container">
        <Navbar.Brand>
          <Link to="/home">
            <div className="custom-logo">
              <img className="logo-img" src={logoImg} alt="" />
            </div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-collapse" />

        <Navbar.Collapse id="navbar-collapse">
          <Nav className="ml-auto custom-links">
            <Link to="/ratelist" className="custom-link">
              <div className="custom-icon-wrapper">
                <span className="balance-text">Rate List</span>
              </div>
            </Link>
            <Link to="/order-history" className="custom-link">
              <div className="custom-icon-wrapper">
                <span className="balance-text">Order History</span>
              </div>
            </Link>
            <Link to="/invoice" className="custom-link">
              <div className="custom-icon-wrapper">
                <span className="balance-text">Invoice</span>
              </div>
            </Link>
            <Link to="/wallet" className="custom-link">
              <div className="custom-icon-wrapper">
                <i className="balance-icon fas fa-coins"></i>
                <span className="balance-text">Balance: {props.balance}</span>
              </div>
            </Link>
            <Link to="/" className="custom-link" >
              <div
                className="custom-icon-wrapper logout-wrapper"
                onClick={() => {
                  localStorage.clear();
                }}
              >
                <i className="logout-icon fas fa-sign-out-alt"></i>
              </div>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default CustomNavbar;
