import axios from 'axios';
import moment from "moment/moment";
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import Navbar from '../component/Navbar';
import './css/newhome.css';
const PAGE_SIZE = 10;
function NewHomelab() {
  const userdetails = JSON.parse(localStorage.getItem('userdata'));
  const [balance, setbalance] = useState();

  const [value, setvalue] = useState(0);
  const [orderdata, setorderdata] = useState([]);
  const [phleboorderdata, setphleboorderdata] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [bookingIds, setbookingIds] = useState([]);
  const [reports, setreports] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const config = {
    headers: { Authorization: `Bearer ${userdetails.token}` },
  };
  const convertToIST = (dateString) => {
    const dateUTC = moment.utc(dateString);
    const dateIST = dateUTC.local().format("DD-MM-YYYY hh:mm A");
    return dateIST;
  };
  async function getdata(page) {
    setisActive(true);
    try {
      const result = await axios.get(
        `https://apiv2.labridge.in/bookTestB2b/orders/${userdetails.user._id}?page=${page}`,
        config
      );
      setorderdata(result.data.orders);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setisActive(false);
  }
  const renderPaginationControls = () => {
    if (orderdata.length > 0) {
      return (
        <div className="pagination-controls">
          <span
            className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={() => {
              if (currentPage > 1) {
                getdata(currentPage - 1);
              }
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </span>
          <span className="page-number">Page {currentPage}</span>
          <span
            className={`pagination-arrow ${orderdata.length < PAGE_SIZE ? 'disabled' : ''}`}
            onClick={() => {
              if (orderdata.length >= PAGE_SIZE) {
                getdata(currentPage + 1);
              }
            }}
          >
            <i className="fas fa-chevron-right"></i>
          </span>
        </div>
      );
    }
    return null;
  };
  const renderOrders = () => {
    if (orderdata.length > 0) {
      return (
        <div className='container'>
    <div className="order-history-table ">
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>Total Price</th>
            <th>Status</th>
            <th>Date Ordered</th>
            <th>Test Name</th>
            <th>Test Status</th>
            <th>Report</th>
          </tr>
        </thead>
        <tbody>
          {orderdata.map((order) => (
            <tr key={order.id}>
              <td>{order.patientName}</td>
              <td>${order.totalprice}</td>
              <td>{order.status}</td>
              <td>{new Date(order.dateOrdered).toLocaleDateString()}</td>
              <td>{order.orderItems[0].product.testName}</td>
              <td>{order.orderItems[0].status}</td>
              <td>
                {order.orderItems[0].Report && (
                  <a href={order.orderItems[0].Report} target="_blank" rel="noopener noreferrer">
                    View Report
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
    </div>
      );
    }
  };
  async function cancelorder(id) {
    const result = await axios.post(
      `https://apiv2.labridge.in/bookTestB2b/hospi/cancel`,
      {
        bookingid: id,
      },
      config
    );
    if (result.data.success === true) {
      toast.success("Order Cancelled Successfully");
      getdata();
    } else {
      toast.error(result.data.msg);
    }
  }
  async function changeurl(originalURL){
    try {
     
      // var segmentToRemove = '//ConvertPDF_QR.ashx?id="D:\\LabBridgePublish\\LabBridgePublish';

      // // Remove the segment from the URL
      // var trimmedURL = originalURL.replace(segmentToRemove, '');
      
      // // Remove the trailing double-quote character
      // trimmedURL = trimmedURL.replace(/"$/, '');
    const response = await axios.post('https://apiv2.labridge.in/bookTestB2b/download-pdf', {
      pdflink:originalURL
    }, {
      responseType: 'blob' // Specify the expected response type
    });
  
    const blobUrl = URL.createObjectURL(response.data);
  
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = 'document.pdf';
    // Trigger the download programmatically
    link.dispatchEvent(new MouseEvent('click'));
  
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
  
    }

  async function getbalance(){
    const result = await axios.get(`https://apiv2.labridge.in/wallet/walletbalance/${userdetails.user._id}`,config);
    setbalance(result.data.data.balance);
  }

  useEffect(() => {
   
    getdata(currentPage); // Call getdata to load initial page
    
  }, [currentPage]);
  return (
    <div> <Navbar balance={balance}/>
     <div className="welcome-message">
      Welcome <span className="labname">{userdetails.user.labName}</span>!
    </div>
   <div className='container'>
   {renderOrders()}
   </div>
    </div>
  )
}

export default NewHomelab;