import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';
import leftArrow from "../images/arrow-left-solid.svg";
import rightArrow from "../images/arrow-right-solid.svg";
import NoResult from '../images/no-result-found.png';
const PAGE_SIZE = 10;


export default function Invoice() {

  const userdetails = JSON.parse(localStorage.getItem("userdata"));
  const [orderdata, setorderdata] = useState([]);
  const [isActive, setisActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);


  function checklogin() {
    if (!localStorage.getItem("userdata")) {
      history("/");
    }
  }
  const history = useNavigate();


  useEffect(() => {
    checklogin();
  })

  const config = {
    headers: { Authorization: `Bearer ${userdetails.token}` },
  };


  async function getdata(page) {
    setisActive(true);
    try {
      const result = await axios.get(
        `https://apiv2.labridge.in/bookTestB2b/orders/${userdetails.user._id}?page=${page}`,
        config
      );
      setorderdata(result.data.orders);
      setTotalPages(result.data.totalPages);
      setCurrentPage(page);
      setisActive(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setisActive(false);
  }
  const renderPaginationControls = () => {
    if (orderdata.length > 0) {
      return (
        <div className="pagination-controls">
          <div>
            <span className="page-number">Showing <b>{currentPage}</b> Page of <b>{totalPages}</b> Pages</span>
          </div>
          <div className="d-flex" style={{marginRight: '50px'}}>
  
            <img
              src={leftArrow}
              alt= ""
              className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={() => {
                if (currentPage > 1) {
                  getdata(currentPage - 1);
                }
              }}
            />
            <img
              src={rightArrow}
              alt=""
              className={`pagination-arrow ${orderdata.length < PAGE_SIZE ? 'disabled' : ''}`}
              onClick={() => {
                if (orderdata.length >= PAGE_SIZE) {
                  getdata(currentPage + 1);
                }
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  };


  useEffect(() => {
  getdata(currentPage); // Call getdata to load initial page
  // getdata();
  }, [])

  const convertToIST = (dateString) => {
    const dateUTC = moment.utc(dateString);
    const dateIST = dateUTC.local().format('DD-MM-YYYY hh:mm A');
    return dateIST;
  };

  const sendData = (data) => {
    localStorage.setItem('billingDetails', JSON.stringify(data));
    history('/billing-form');
  }
  const viewBill = (id) => {
    localStorage.setItem("billId", id);
    history('/billing');
  }


  return (<>
   
    <Navbar />
        <div className='container'>
          <h5 className='p-3'>Invoice  Listing</h5>
          {orderdata.length > 0 ? orderdata.map((data, index) => {
                  return (
                    <>
                    <div className="order-div m-3" key={index}>
                      <div className="order-div-head d-flex justify-content-between">
                        <div className="d-flex  align-items-center">PatientName: {data.patientName}</div>
                        <div>
                          Total: {data.totalprice} | {data.orderItems.length} Test |  {" "}
                          {
                            data.billNumber === '' ?
                          (<span>
                            <button className="btn btn-success" onClick={() => {sendData(data)}}>
                              Create Bill
                            </button>
                          </span>) : (<span>
                            <button className="btn btn-success" onClick={() => {viewBill(data.billNumber)}}>
                              View Bill
                            </button>
                          </span>)
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between p-3">
                        <p className="status-p">OrderId: {data.id}</p>
                        <p className="date-p">{convertToIST(data.dateOrdered)}</p>
                      </div>
                      {data.orderItems.map((items ,index) => {
                        return (
                          <div className="d-flex justify-content-between ps-3 pe-3" key={`${index}p`}>
                            <p className="test-name">
                              {items.product.testName}
                            </p>
                            <p className="price-p">
                              {items.product.testPrice}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    </>
);
                }) : 
                <div style={{display: 'flex', justifyContent: 'center', }}>
                  <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={NoResult} style={{height: '250px'}} alt=''/>
                    </div>
                    <h5 style={{color: '#DCDCDC'}}>No Booking Made Yet</h5>
                  </div>
                </div>
}
{renderPaginationControls()}

</div>
    <Footer />
 
    </>
  )
}
