import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import banner from "../images/logo.png";

function Login1() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState('');
  const history = useNavigate();
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }
  const handleSubmit1 = async (event) => {
    event.preventDefault();

    if (showEmail === true) {
      setShowEmail(false);
      setShowPassword(true);
    }  else {
      setShowEmail(true);
      setShowPassword(false);
    }
  };
      
  const handleResetPassword = async () => {
    try {
        const response = await axios.post('https://apiv2.labridge.in/auth/passwordreset/initiate', { email });
        if (response.data.success) {
            setSuccess(true);
            toast.success("Password reset link sent on mail. If not received please get in touch with us");
            setError('');
        } else {
            setSuccess(false);
            toast.error(response.data.error || 'Password reset initiation failed');
            setError(response.data.error || 'Password reset initiation failed');
        }
    } catch (error) {
        setSuccess(false);
        setError('Password reset initiation failed');
    }
};  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      email: email,
      password: password,
    }

    const result =  await axios.post(`https://apiv2.labridge.in/auth/lab/login`, body);

    if (result.status === 200) {
      localStorage.setItem("userdata", JSON.stringify(result.data));
      history('/home');
    }
    else{
      toast.error("Invalid UserName/Password");
    }
  };

  return (
    <><div className='container'>
      {/* <div class="alert alert-info mt-4" role="alert">
      🎉 Special Offer from 24/10/2023 to 30/10/2023 . Special Offer Basic @ Rs199 /- and Special Offer Advance @ Rs499 /-
      </div> */}
          <div className="row" style={{ alignItems: 'center', height: '80vh' }}>
              <div className='col-md-6 col-sm-12'style={{ justifyContent: 'center', display: 'flex' }} >
                <div className='bannerClass'>
                  <img src={banner} alt='banner' className='imgClass' />
                </div>
              </div>
             <div className='col-md-6 col-sm-12'>
               { showEmail &&  <form className="login" onSubmit={handleSubmit}>
                      <h2>Welcome, User!</h2>
                      <p>Please log in</p>
                      <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} autoComplete='userName' required />
                      <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} autoComplete="current-password" required />
                      <div>
                          <button type="submit" className='submitButton' defaultValue="Log In">LogIn</button>
                          <Link onClick={handleSubmit1} style={{ textDecoration: 'underLine'}}>Forgot password?</Link>
                      </div>
                  </form>}
                  { showPassword &&    <div className="login" >
                    <h2>Forgot Password</h2>
                    <p>Please enter the Email Id</p>
                    <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} required />
                    <div className='d-flex  alin-items-center'>
                      <button onClick={handleResetPassword} type="submit" className='submitButton' defaultValue="Log In">Send Link</button>
                      <Link className='mt-2' onClick={handleSubmit1} style={{ textDecoration: 'underLine'}}>Login now?</Link>
                    </div>
                  </div>} 
              </div>
          </div>
          <ToastContainer autoClose={4000} theme="colored" />
      </div></>
  )
}

export default Login1