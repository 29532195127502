import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';

const BillingForm = () => {

  function checklogin() {
    if (!localStorage.getItem("userdata")) {
      history("/");
    }
  }
  const history = useNavigate();


  useEffect(() => {
    checklogin();
  })

  const jsonData = JSON.parse(localStorage.getItem('billingDetails'));

  const [patientName] = useState(jsonData.patientName || '');
  const [contactNumber] = useState(jsonData.mobile || '');
  const [sourceName] = useState(jsonData.labhpl || '');
  const [age] = useState(jsonData.age || '');
  const [gender] = useState(jsonData.gender || '');
  const[bookingdate] = useState(jsonData.dateOrdered || '');
  const [orderItems, setOrderItems] = useState(jsonData.orderItems || []);
  const [discount, setDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  // Calculate total amount based on the order items
  const calculateTotal = () => {
    let total = 0;
    orderItems.forEach((item) => {
      if (!isNaN(item.amount)) {
        total += parseFloat(item.amount);
      }
    });
    setTotalAmount(total);
  };

  // Calculate final total with discount
  const calculateFinalTotal = () => {
    const discountValue = parseFloat(discount) || 0;
    setFinalTotal(totalAmount - discountValue);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('order', orderItems);
    jsonData.orderItems = orderItems;

    console.log('json', jsonData);
    let newObj = [];

    orderItems.forEach((order) => {
      if (order !== null) {
        const data = {
          testName: order.product.testName,
          price: Number(order.amount),
          quantity: 1,
        }
        newObj.push(data);
      }
    })
    console.log(newObj);
    let mainObject = {};
    localStorage.removeItem("billId");
    if (newObj !== null && newObj.length > 0) {
      mainObject = {
        bookingId: jsonData._id,
        patientName: jsonData.patientName,
        age: jsonData.age,
        gender: jsonData.gender,
        sourceName: jsonData.labhpl,
        mobile: jsonData.mobile,
        date: new Date(),
        bookingdate:bookingdate,
        total: totalAmount,
        docName: jsonData.doctorName,
        discount: Number(discount),
        finalAmount: finalTotal ? finalTotal : totalAmount,
        tests: newObj
      };
      const result = await axios.post(
        `https://apiv2.labridge.in/bill/add`, mainObject);
      console.log('billing', result);
      if (result !== null || result !== undefined) {
        console.log(result.data.data);
        if (result.data.data !== "") {
          localStorage.setItem("billId", result.data.data);
          if(localStorage.getItem("billId")) {
            history('/billing');
          }
        }
      }
    }
    // console.log('Obj', mainObject);
    // Process form data as needed or submit to backend
  };

  return (
    <>
      <Navbar />
      <div className="container p-3">
        <h5>Billing Form</h5>
        <form onSubmit={handleSubmit} className="p-3">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label>
                <b>Patient Name:</b> {patientName}
              </label>
            </div>
            <div className="col-md-6 col-sm-12">
              <label>
                <b>Age:</b> {age}
              </label>
            </div>
            <div className="col-md-6 col-sm-12">
              <label>
                <b>Gender:</b> {gender}
              </label>
            </div>
            <div className="col-md-6 col-sm-12">
              <label>
                <b>Date:</b> {new Date().toLocaleDateString()}
              </label>
            </div>
            <div className="col-md-6 col-sm-12">
              <label>
                <b>Booking Date:</b> {bookingdate}
              </label>
            </div>
            <div className="col-md-6 col-sm-12">
              <label>
                <b>Source Name:</b> {sourceName}
              </label>
            </div>
          </div>

          <table style={{ marginTop: '20px', tableLayout: 'auto' }}>
            <thead style={{ padding: '10px', marginBottom: '10px' }}>
              <tr style={{ border: '1px solid black', marginBottom: '5px' }}>
                <th style={{ borderRight: '1px solid black' }}>Test Name</th>
                <th style={{ borderRight: '1px solid black' }}>Quantity</th>
                <th style={{ borderRight: '1px solid black' }}>Test Price</th>
                <th>Billing Price</th>
              </tr>
            </thead>
            {/* <div style={{ height: '10px' }}></div> */}
            <tbody>
              {orderItems.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    border: '1px solid black',
                    marginBottom: '5px',
                    padding: '5px',
                  }}
                >
                  <td className="" style={{ borderRight: '1px solid black' }}>
                    <b>{item.product.testName}</b>
                  </td>
                  <td style={{ borderRight: '1px solid black' }}>1</td>
                  <td style={{ borderRight: '1px solid black' }}>
                    {item.product.testPrice}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={item.amount}
                      style={{ border: 'none', textAlign: 'center' }}
                      min={item.product.testPrice}
                      onChange={(e) => {
                        const updatedOrderItems = [...orderItems];
                        updatedOrderItems[index].amount = e.target.value;
                        setOrderItems(updatedOrderItems);
                        calculateTotal();
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h4 style={{ marginTop: '20px', textDecoration: 'underline' }}>
            Total Bill:
          </h4>
          <table style={{ marginTop: '20px' }}>
            <tbody>
              <tr>
                <th>Total</th>
                <td>{totalAmount}</td>
              </tr>
              <tr>
                <th>Discount (in ruppees)</th>
                <td>
                  <input
                    type="text"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    onBlur={calculateFinalTotal}
                    style={{border: 'none', textAlign: 'center'}}
                  />
                </td>
              </tr>
              <tr>
                <th>Final Price</th>
                <td>{discount > 0 ? finalTotal : totalAmount}</td>
              </tr>
            </tbody>
          </table>

          <button className="billingBtn" type="submit">
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default BillingForm;

