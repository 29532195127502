import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CoreValues from '../component/CoreValues';
import Footer from '../component/Footer';
import Marketplace from "../component/Marketplace";
import Navbar from '../component/Navbar';
import Ourmission from '../component/Ourmission';
import OurservicesLab from '../component/OurservicesLab';
import SpecialofferComponent from '../component/SpecialofferComponent';
import Top from '../component/Top';
import Whoweare from '../component/Whoweare';

function Homelab() {
  const userdetails = JSON.parse(localStorage.getItem("userdata"));
  const config = {
    headers: { Authorization: `Bearer ${userdetails.token}` },
  };
  const [balance, setbalance] = useState();
  const history = useNavigate();
  function checklogin(){
    if(!localStorage.getItem("userdata")){
      history("/")
    }
  }
  async function getbalance(){
    const result = await axios.get(`https://apiv2.labridge.in/wallet/walletbalance/${userdetails.user._id}`,config);
    setbalance(result.data.data.balance);
  }
  useEffect(()=>{
     checklogin();
     getbalance();
     
  },[])
 
  return (
    <div>
        <Navbar balance={balance}/>
         <Top/>
         <SpecialofferComponent/>
        <OurservicesLab/>
        <Marketplace/>
        <Ourmission/>
        <CoreValues/>
        <Whoweare/>
        {/* <Contactus/> */}
        {/* <Ourdiagservice/> */}
        <Footer/>
       
    </div>
  )
}

export default Homelab;