
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import ReactPaginate from 'react-paginate';
import Navbar from '../component/Navbar';
function PackageDetails() {
    const userdetails = JSON.parse(localStorage.getItem('userdata'));
    const [packages, setPackages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]); // Suggestions state
  
    const packagesPerPage = 5;
    
  
    useEffect(() => {
      const fetchData = () => {
        fetch(`https://apiv2.labridge.in/apiforsubmission/packages/${userdetails.user._id}`)
          .then((response) => response.json())
          .then((data) => setPackages(data))
          .catch((error) => console.error('Error fetching data:', error));
      };
  
      fetchData();
    }, [userdetails.user._id]);
  
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    const handleSuggestionsFetchRequested = ({ value }) => {
      setSuggestions(getSuggestions(value));
    };
  
    const handleSuggestionsClearRequested = () => {
      setSuggestions([]);
    };
    const filteredPackages = packages.filter((pkg) =>
    pkg.package.testName.toLowerCase().includes(searchTerm.toLowerCase())
  );
    const displayedPackages = filteredPackages.slice(
        currentPage * packagesPerPage,
        (currentPage + 1) * packagesPerPage
      );
   
  
    const pageCount = Math.ceil(filteredPackages.length / packagesPerPage);
  
    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
  
      return inputLength === 0
        ? []
        : packages.filter(
            (pkg) =>
              pkg.package.testName.toLowerCase().slice(0, inputLength) === inputValue
          );
    };
  
    const renderSuggestion = (suggestion) => (
      <div className='suggestiondiv'>{suggestion.package.testName}</div>
    );
  
    const inputProps = {
      placeholder: 'Search by Package Name',
      value: searchTerm,
      className:'form-control',
      id:"floatingInput",
      width:"40%",
      onChange: (e, { newValue }) => setSearchTerm(newValue),
    };
  return (
   <> <Navbar/>
    <div className="container">
       <h1 className='mt-4'>Package Details</h1>
      {/* Autosuggest input */}
      <div className='mt-2 mb-2'>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={(suggestion) => suggestion.package.testName}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      /></div>
      {displayedPackages.map((packageData, index) => (
        <div key={index} className="package">
         <center> <h2>Package Name:  {packageData.package.testName}</h2></center>
       <center>   <p>Package Price: Rs.{packageData.package.testPrice}</p></center>
          <h3>Tests: </h3>
          <ul>
            {packageData.tests.map((test, testIndex) => (
              <li key={testIndex}>
                <p>Test: <b>{test.report}</b></p>
                <p>Specimen:<b> {test.SpecimenName}</b></p>
                <hr></hr>
              </li>
              
            ))}
          </ul>
          
        </div>
      ))}
      {packages.length > packagesPerPage && (
        <div className="pagination">
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      )}
    </div></>
  )
}

export default PackageDetails