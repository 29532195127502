import React from 'react';
import { Link } from "react-router-dom";

function OurservicesLab() {
  
    return (
        <div className='ourservice-div'>
            <div className='container contedit'>
                <p className='our-div'>Our <span className='service-div'>Service →</span></p>
                <div className='service-card-maindiv row'>
                  <div className='p-2 col-12 col-md-6 col-lg-4 '>  
                  <div className='service-card1  ' >
                  <Link to="/book">Patient Management</Link>
                        </div>
                        </div>
                 <div className='p-2 col-12 col-md-6 col-lg-4'>
                        <div className='service-card2 '>
                        <Link to="book-test-everywhere">Phlebotomist Services </Link>
                        
                        </div>
                        
                        </div>
                        <div className='p-2  col-12 col-md-6 col-lg-4'>
                      <div className='service-card3'>
                      <Link to="/ratelist">Rate List </Link>
                        </div>
                        </div>
                        <div className='p-2  col-12 col-md-6 col-lg-4'>
                      <div className='service-card3'>
                      <Link to="">Lab Products <p className='com-soon'>Coming Soon...</p></Link>
                        </div>
                        </div>
                </div>
            </div>
        </div>
      )
}

export default OurservicesLab;