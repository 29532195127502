import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Circles } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";
import leftArrow from "../images/arrow-left-solid.svg";
import rightArrow from "../images/arrow-right-solid.svg";
import NoResult from "../images/no-result-found.png";
const PAGE_SIZE = 10;

export default function OrderLabNew() {
  const userdetails = JSON.parse(localStorage.getItem("userdata"));
  const [value, setvalue] = useState(0);
  const [orderdata, setorderdata] = useState([]);
  const [phleboorderdata, setphleboorderdata] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [bookingIds, setbookingIds] = useState([]);
  const [reports, setreports] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [balance, setbalance] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  async function getbalance() {
    const result = await axios.get(
      `https://apiv2.labridge.in/wallet/walletbalance/${userdetails.user._id}`,
      config
    );
    setbalance(result.data.data.balance);
  }
  function checklogin() {
    if (!localStorage.getItem("userdata")) {
      history("/");
    }
  }

  const history = useNavigate();

  useEffect(() => {
    checklogin();
  }, []);

  const openImageViewer = (img) => {
    setCurrentImage(img);
    setIsViewerOpen(true);
  };
  async function getdata1(page) {
    setisActive(true);
    try {
      const result = await axios.get(
        `https://apiv2.labridge.in/admin/api/orderspatient/?user=${userdetails.user._id}&patientName=${searchTerm}`,
        config
      );
      setorderdata(result.data.orders);
      setTotalPages(result.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setisActive(false);
  }

  const getOrdersByPatientName = () => {
    getdata1(currentPage);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const config = {
    headers: { Authorization: `Bearer ${userdetails.token}` },
  };

  const renderPaginationControls = () => {
    if (orderdata.length > 0) {
      return (
        <div className="pagination-controls">
          <div>
            <span className="page-number">
              Showing <b>{currentPage}</b> Page of <b>{totalPages}</b> Pages
            </span>
          </div>
          <div className="d-flex" style={{ marginRight: "50px" }}>
            <img
              src={leftArrow}
              alt=""
              className={`pagination-arrow ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={() => {
                if (currentPage > 1) {
                  getdata(currentPage - 1);
                }
              }}
            />
            <img
              src={rightArrow}
              alt=""
              className={`pagination-arrow ${
                orderdata.length < PAGE_SIZE ? "disabled" : ""
              }`}
              onClick={() => {
                if (orderdata.length >= PAGE_SIZE) {
                  getdata(currentPage + 1);
                }
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  async function getdata(page) {
    setisActive(true);
    try {
      const result = await axios.get(
        `https://apiv2.labridge.in/bookTestB2b/orders/${userdetails.user._id}?page=${page}`,
        config
      );
      setorderdata(result.data.orders);
      setTotalPages(result.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setisActive(false);
  }

  async function getReport2(integrationBookingId) {
    setIsLoading(true);
    try {
      const reqBody = {
        ExternalRegnPDF: {
          ExternalPatientId: integrationBookingId,
          LabID: "f6475862-63d7-4a39-aa03-2e9fbea7e07f",
        },
      };
      const result = await axios.post(
        "https://lab.labridge.in/Services/MachineService.svc/ExternalRegistrationSynchForLab_PDF",
        reqBody
      );
      if (result.data.d[0].PdfNamewithHeader !== "Null") {
        const url = "https://lab.labridge.in";

        let pdfwitourHeader = result.data.d[0].PdfNamewithoutHeader;

        const mainURL = pdfwitourHeader.replace("~", url);
        axios({
          url: mainURL,
          method: "GET",
          responseType: "blob", // This ensures the response is treated as a binary blob
        }).then((response) => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', Date.now()+".pdf");
          // document.body.appendChild(link);
          // link.click();
          // window.URL.revokeObjectURL(url);
          axios({
            url: mainURL,
            method: "GET",
            responseType: "blob", // This ensures the response is treated as a binary blob
          }).then((response) => {
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', Date.now()+".pdf");
            // document.body.appendChild(link);
            // link.click();
            // window.URL.revokeObjectURL(url);
            saveAs(new Blob([response.data]), Date.now() + ".pdf");
          });
        });
      } else {
        toast.error("Report is getting ready please wait for sometime");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching the report.");
    } finally {
      setIsLoading(false); // Set loading to false when the function is done
    }
  }
  async function getReport(integrationBookingId) {
    setIsLoading(true);
    try {
      const reqBody = {
        ExternalRegnPDF: {
          ExternalPatientId: integrationBookingId,
          LabID: "f6475862-63d7-4a39-aa03-2e9fbea7e07f",
        },
      };
      const result = await axios.post(
        "https://lab.labridge.in/Services/MachineService.svc/ExternalRegistrationSynchForLab_PDF",
        reqBody
      );
      if (result.data.d[0].PdfNamewithHeader !== "Null") {
        const url = "https://lab.labridge.in";
        let pdf = result.data.d[0].PdfNamewithHeader;

        const mainURL = pdf.replace("~", url);
        axios({
          url: mainURL,
          method: "GET",
          responseType: "blob", // This ensures the response is treated as a binary blob
        }).then((response) => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', Date.now()+".pdf");
          // document.body.appendChild(link);
          // link.click();
          // window.URL.revokeObjectURL(url);
          saveAs(new Blob([response.data]), Date.now() + ".pdf");
        });
      } else {
        toast.error(`Report Status : ${result.data.d[0].CurrentState}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching the report.");
    } finally {
      setIsLoading(false); // Set loading to false when the function is done
    }
  }
  //A00016   A00028
  // const integrationId = 'A00016';
  async function changeurl(originalURL) {
    try {
      // var segmentToRemove = '//ConvertPDF_QR.ashx?id="D:\\LabBridgePublish\\LabBridgePublish';

      // // Remove the segment from the URL
      // var trimmedURL = originalURL.replace(segmentToRemove, '');

      // // Remove the trailing double-quote character
      // trimmedURL = trimmedURL.replace(/"$/, '');
      const response = await axios.post(
        "https://apiv2.labridge.in/bookTestB2b/download-pdf",
        {
          pdflink: originalURL,
        },
        {
          responseType: "blob", // Specify the expected response type
        }
      );

      const blobUrl = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "document.pdf";
      // Trigger the download programmatically
      link.dispatchEvent(new MouseEvent("click"));

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  }

  async function cancelorder(id) {
    const result = await axios.post(
      `https://apiv2.labridge.in/bookTestB2b/hospi/cancel`,
      {
        bookingid: id,
      },
      config
    );
    if (result.data.success === true) {
      toast.success("Order Cancelled Successfully");
      window.location.reload(true);
    } else {
      toast.error(result.data.msg);
    }
  }
  const renderOrders = () => {
    if (orderdata.length > 0) {
      return orderdata.map((data, index) => (
        <>
          {data.status == "Partialy Authorized" ||
          data.status == "Authorized" ||
          data.status == "Printed" ||   data.status == "Partialy Print" ? (
            <div className="order-div m-3" key={index}>
              <div className="order-div-head d-flex justify-content-between">
                <div className="d-flex  align-items-center">
                  PatientName : {data.patientName}
                </div>
                <div>
                  Total: {data.totalprice} | {data.orderItems.length} Test |
                  Status: {data.status} |{" "}
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      if (!isLoading) {
                        getReport(data.integrationBookingId);
                      }
                    }}
                    disabled={isLoading} // Disable the button when loading
                  >
                    {isLoading ? "Loading..." : "Report"}
                  </button>
                  <button
                    className="btn btn-success ms-1"
                    onClick={() => {
                      if (!isLoading) {
                        getReport2(data.integrationBookingId);
                      }
                    }}
                    disabled={isLoading} // Disable the button when loading
                  >
                    {isLoading ? "Loading..." : "ReportWLH"}
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between p-3">
                <p className="status-p">OrderId: {data.id}</p>
                <p className="date-p">
                  <b>Booking Date :</b> {convertToIST(data.dateOrdered)}
                </p>
              </div>
              {data.orderItems.map((items, index) => {
                return (
                  <div
                    className="d-flex justify-content-between ps-3 pe-3"
                    key={`${index}p`}
                  >
                    <p className="test-name">{items.product.testName}</p>
                  </div>
                );
              })}
              <hr></hr>
              <div className=" ps-3 pe-3">
                {data.sampletype ? (
                  data.sampletype.sampledetail.map((sampledata, index) => {
                    return (
                      <p className="status-p">
                        {sampledata.sampleType}:{sampledata.barcode}
                      </p>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
              {data.status == "Cancelled" ? (
                <p className="ms-3" style={{ color: "red" }}>
                  <b>Cancelled</b>
                </p>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="order-div m-3" key={index}>
              <div className="order-div-head d-flex justify-content-between">
                <div className="d-flex  align-items-center">
                  PatientName : {data.patientName}
                </div>
                <div>
                  Total: {data.totalprice} | {data.orderItems.length} Test | Status: {data.status} 
                  {data.status === "Registration" || data.status === "Cancelled" || data.status === "Accession" ?  (
                    <span>
                    {/* {data.status !== "Accession" ?<>| <button
                        className="btn btn-danger"
                        onClick={() => {
                          cancelorder(data.integrationBookingId);
                        }}
                        disabled={data.status != "Registration" ? true : false}
                      >
                        Cancel
                      </button></> :<></>} */}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      {/* <button
                        className="btn btn-danger"
                        onClick={() => {
                          cancelorder(data.integrationBookingId);
                        }}
                        disabled={data.status != "Registration" ? true : false}
                      >
                        Cancel
                      </button> */}
                    </span>
                  )}{" "}
                </div>
              </div>
              <div className="d-flex justify-content-between p-3">
                <p className="status-p">OrderId: {data.id}</p>
                <p className="date-p">
                  <b>Booking Date :</b> {convertToIST(data.dateOrdered)}
                </p>
              </div>
              {data.orderItems.map((items, index) => {
                return (
                  <div
                    className="d-flex justify-content-between ps-3 pe-3"
                    key={`${index}p`}
                  >
                    <p className="test-name">{items.product.testName}</p>
                  </div>
                );
              })}
              <hr></hr>
              <div className=" ps-3 pe-3">
                {data.sampletype ? (
                  data.sampletype.sampledetail.map((sampledata, index) => {
                    return (
                      <p className="status-p">
                        {sampledata.sampleType}:{sampledata.barcode}
                      </p>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </>
      ));
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={NoResult} style={{ height: "250px" }} alt="" />
            </div>
            <h5 style={{ color: "#DCDCDC" }}>No Booking Made Yet</h5>
          </div>
        </div>
      );
    }
  };

  async function getphlebodata() {
    const result = await axios.get(
      `https://apiv2.labridge.in/phlebotomos/getphleboorder/${userdetails.user._id}`,
      config
    );
    setphleboorderdata(result.data.data);
  }

  const convertToIST = (dateString) => {
    const dateUTC = moment.utc(dateString);
    const dateIST = dateUTC.local().format("DD-MM-YYYY hh:mm A");
    return dateIST;
  };

  useEffect(() => {
    checklogin();
    getbalance();
    getdata(currentPage); // Call getdata to load initial page
    getphlebodata();
  }, [currentPage]);
  return (
    <div>
   
        <Navbar balance={balance} />
        <div className="container">
          <div className="add-detail-div mt-4">
            <div>← Order history</div>
            <div className="d-flex">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => {
                  if (e.target.value) {
                    setSearchTerm(e.target.value);
                  } else {
                    setSearchTerm(e.target.value);
                    getdata(currentPage);
                  }
                }}
              />
              <button
                className="btn btn-outline-success"
                type="submit"
                onClick={getOrdersByPatientName}
              >
                Search
              </button>
            </div>
          </div>
          <div className="loaderBack"  style={{ marginBottom: "10px", marginTop: "10px" }}>
           {isActive ? <div className="d-flex flex-column justify-content-center align-items-center" style={{height:"50vh", width:"100%"}}><Circles
  height="80"
  width="80"
  color="#3C9BD6"
  ariaLabel="circles-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={isActive}
  /> <p>Loading...</p> </div>:<div className="MuiPaper-root MuiPaper-elevation1 p-2">{renderOrders()} {renderPaginationControls()}</div> }
          </div>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} theme="colored" />
    
    </div>
  );
}
